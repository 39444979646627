// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";
import { ClientsFirstVisitFragmentDoc } from "../../fragments/clientsFirstVisit.graphql.types";
import { TelehealthIconFieldsFragmentDoc } from "../../fragments/telehealth/telehealthFields.graphql.types";

const defaultOptions = {} as const;
export type VisitFieldsFragment = {
  __typename?: "visit";
  id: string;
  startTime: string;
  status: string;
  reviewStatus: string;
  appointment?: {
    __typename?: "SchedulingAppointment";
    id: string;
    startTime?: string | null;
    endTime?: string | null;
  } | null;
  invoice?: { __typename?: "invoice"; id: string; status: string } | null;
  client: {
    __typename?: "client";
    id: string;
    firstName: string;
    lastName: string;
    note: string;
    hadAdverseReaction: boolean;
    formSubmissions: Array<{
      __typename?: "formSubmission";
      id: string;
      gfeStatus: string;
      submittedAt?: string | null;
      archivedAt?: string | null;
      medspa: { __typename?: "medspa"; id: string };
    }>;
    consentFormSignatures: Array<{
      __typename?: "consentFormSignature";
      id: string;
      visitId?: string | null;
      signedAt?: string | null;
      consentForm: {
        __typename?: "consentForm";
        id: string;
        sendOnce: boolean;
      };
    }>;
    hasItemsInWallet: {
      __typename?: "walletTransactionAggregate";
      aggregate?: {
        __typename?: "walletTransactionAggregateFields";
        sum?: {
          __typename?: "walletTransactionSumFields";
          amount?: string | null;
        } | null;
      } | null;
    };
    clientMemberships: Array<{
      __typename?: "ClientMembership";
      id: string;
      membership: { __typename?: "Membership"; id: string; title: string };
    }>;
    visits: Array<{ __typename?: "visit"; id: string }>;
  };
  serviceMenuItemLines: Array<{
    __typename?: "ServiceMenuItemLine";
    id: string;
    gfeStatus?: string | null;
    serviceMenuItem: {
      __typename?: "MedspaServiceMenuItem";
      id: string;
      name: string;
    };
  }>;
  provider: {
    __typename?: "user";
    id: string;
    firstName: string;
    lastName: string;
  };
};

export type VisitsQueryVariables = Types.Exact<{
  date: Types.Scalars["timestamptz"]["input"];
  provider: Types.Scalars["bigint"]["input"];
  medspaId: Types.Scalars["bigint"]["input"];
}>;

export type VisitsQuery = {
  __typename?: "query_root";
  visit: Array<{
    __typename?: "visit";
    id: string;
    startTime: string;
    status: string;
    reviewStatus: string;
    isVisitTelehealth?: boolean | null;
    appointment?: {
      __typename?: "SchedulingAppointment";
      id: string;
      startTime?: string | null;
      endTime?: string | null;
    } | null;
    invoice?: { __typename?: "invoice"; id: string; status: string } | null;
    client: {
      __typename?: "client";
      id: string;
      firstName: string;
      lastName: string;
      note: string;
      hadAdverseReaction: boolean;
      formSubmissions: Array<{
        __typename?: "formSubmission";
        id: string;
        gfeStatus: string;
        submittedAt?: string | null;
        archivedAt?: string | null;
        medspa: { __typename?: "medspa"; id: string };
      }>;
      consentFormSignatures: Array<{
        __typename?: "consentFormSignature";
        id: string;
        visitId?: string | null;
        signedAt?: string | null;
        consentForm: {
          __typename?: "consentForm";
          id: string;
          sendOnce: boolean;
        };
      }>;
      hasItemsInWallet: {
        __typename?: "walletTransactionAggregate";
        aggregate?: {
          __typename?: "walletTransactionAggregateFields";
          sum?: {
            __typename?: "walletTransactionSumFields";
            amount?: string | null;
          } | null;
        } | null;
      };
      clientMemberships: Array<{
        __typename?: "ClientMembership";
        id: string;
        membership: { __typename?: "Membership"; id: string; title: string };
      }>;
      visits: Array<{ __typename?: "visit"; id: string }>;
    };
    serviceMenuItemLines: Array<{
      __typename?: "ServiceMenuItemLine";
      id: string;
      gfeStatus?: string | null;
      serviceMenuItem: {
        __typename?: "MedspaServiceMenuItem";
        id: string;
        name: string;
      };
    }>;
    provider: {
      __typename?: "user";
      id: string;
      firstName: string;
      lastName: string;
    };
    telehealthVisitDetails?: {
      __typename?: "TelehealthVisitDetails";
      visitType: string;
      videoLink?: string | null;
    } | null;
  }>;
};

export type VisitsWithFiltersQueryVariables = Types.Exact<{
  date: Types.Scalars["timestamptz"]["input"];
  providerQuery: Types.UserBoolExp;
  medspaId: Types.Scalars["bigint"]["input"];
  status:
    | Array<Types.Scalars["String"]["input"]>
    | Types.Scalars["String"]["input"];
}>;

export type VisitsWithFiltersQuery = {
  __typename?: "query_root";
  visit: Array<{
    __typename?: "visit";
    id: string;
    startTime: string;
    status: string;
    reviewStatus: string;
    appointment?: {
      __typename?: "SchedulingAppointment";
      id: string;
      startTime?: string | null;
      endTime?: string | null;
    } | null;
    invoice?: { __typename?: "invoice"; id: string; status: string } | null;
    client: {
      __typename?: "client";
      id: string;
      firstName: string;
      lastName: string;
      note: string;
      hadAdverseReaction: boolean;
      formSubmissions: Array<{
        __typename?: "formSubmission";
        id: string;
        gfeStatus: string;
        submittedAt?: string | null;
        archivedAt?: string | null;
        medspa: { __typename?: "medspa"; id: string };
      }>;
      consentFormSignatures: Array<{
        __typename?: "consentFormSignature";
        id: string;
        visitId?: string | null;
        signedAt?: string | null;
        consentForm: {
          __typename?: "consentForm";
          id: string;
          sendOnce: boolean;
        };
      }>;
      hasItemsInWallet: {
        __typename?: "walletTransactionAggregate";
        aggregate?: {
          __typename?: "walletTransactionAggregateFields";
          sum?: {
            __typename?: "walletTransactionSumFields";
            amount?: string | null;
          } | null;
        } | null;
      };
      clientMemberships: Array<{
        __typename?: "ClientMembership";
        id: string;
        membership: { __typename?: "Membership"; id: string; title: string };
      }>;
      visits: Array<{ __typename?: "visit"; id: string }>;
    };
    serviceMenuItemLines: Array<{
      __typename?: "ServiceMenuItemLine";
      id: string;
      gfeStatus?: string | null;
      serviceMenuItem: {
        __typename?: "MedspaServiceMenuItem";
        id: string;
        name: string;
      };
    }>;
    provider: {
      __typename?: "user";
      id: string;
      firstName: string;
      lastName: string;
    };
  }>;
};

export type NextVisitForClientQueryVariables = Types.Exact<{
  clientId: Types.Scalars["bigint"]["input"];
  medspaId: Types.Scalars["bigint"]["input"];
}>;

export type NextVisitForClientQuery = {
  __typename?: "query_root";
  visit: Array<{
    __typename?: "visit";
    id: string;
    startTime: string;
    status: string;
    reviewStatus: string;
    appointment?: {
      __typename?: "SchedulingAppointment";
      id: string;
      startTime?: string | null;
      endTime?: string | null;
    } | null;
    invoice?: { __typename?: "invoice"; id: string; status: string } | null;
    client: {
      __typename?: "client";
      id: string;
      firstName: string;
      lastName: string;
      note: string;
      hadAdverseReaction: boolean;
      formSubmissions: Array<{
        __typename?: "formSubmission";
        id: string;
        gfeStatus: string;
        submittedAt?: string | null;
        archivedAt?: string | null;
        medspa: { __typename?: "medspa"; id: string };
      }>;
      consentFormSignatures: Array<{
        __typename?: "consentFormSignature";
        id: string;
        visitId?: string | null;
        signedAt?: string | null;
        consentForm: {
          __typename?: "consentForm";
          id: string;
          sendOnce: boolean;
        };
      }>;
      hasItemsInWallet: {
        __typename?: "walletTransactionAggregate";
        aggregate?: {
          __typename?: "walletTransactionAggregateFields";
          sum?: {
            __typename?: "walletTransactionSumFields";
            amount?: string | null;
          } | null;
        } | null;
      };
      clientMemberships: Array<{
        __typename?: "ClientMembership";
        id: string;
        membership: { __typename?: "Membership"; id: string; title: string };
      }>;
      visits: Array<{ __typename?: "visit"; id: string }>;
    };
    serviceMenuItemLines: Array<{
      __typename?: "ServiceMenuItemLine";
      id: string;
      gfeStatus?: string | null;
      serviceMenuItem: {
        __typename?: "MedspaServiceMenuItem";
        id: string;
        name: string;
      };
    }>;
    provider: {
      __typename?: "user";
      id: string;
      firstName: string;
      lastName: string;
    };
  }>;
};

export const VisitFieldsFragmentDoc = gql`
  fragment VisitFields on visit {
    id
    startTime
    appointment {
      id
      startTime
      endTime
    }
    invoice {
      id
      status
    }
    status
    reviewStatus
    client {
      id
      firstName
      lastName
      note
      formSubmissions {
        id
        gfeStatus
        submittedAt
        archivedAt
        medspa {
          id
        }
      }
      consentFormSignatures(
        where: {
          expiredAt: { _isNull: true }
          consentForm: { deleted: { _eq: false } }
        }
      ) {
        id
        visitId
        signedAt
        consentForm {
          id
          sendOnce
        }
      }
      hadAdverseReaction
      hasItemsInWallet: walletTransactionsAggregate {
        aggregate {
          sum {
            amount
          }
        }
      }
      clientMemberships(where: { status: { _neq: "canceled" } }) {
        id
        membership {
          id
          title
        }
      }
      ...ClientsFirstVisit
    }
    serviceMenuItemLines {
      id
      gfeStatus
      serviceMenuItem {
        id
        name
      }
    }
    provider {
      id
      firstName
      lastName
    }
  }
  ${ClientsFirstVisitFragmentDoc}
`;
export const VisitsDocument = gql`
  query Visits($date: timestamptz!, $provider: bigint!, $medspaId: bigint!) {
    visit(
      where: {
        startTime: { _gt: $date }
        provider: { id: { _eq: $provider } }
        status: { _nin: ["cancelled", "cancelled_late"] }
        medspa: { id: { _eq: $medspaId } }
      }
      orderBy: { startTime: ASC }
    ) {
      ...VisitFields
      ...TelehealthIconFields
    }
  }
  ${VisitFieldsFragmentDoc}
  ${TelehealthIconFieldsFragmentDoc}
`;

/**
 * __useVisitsQuery__
 *
 * To run a query within a React component, call `useVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      provider: // value for 'provider'
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useVisitsQuery(
  baseOptions: Apollo.QueryHookOptions<VisitsQuery, VisitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VisitsQuery, VisitsQueryVariables>(
    VisitsDocument,
    options
  );
}
export function useVisitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VisitsQuery, VisitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VisitsQuery, VisitsQueryVariables>(
    VisitsDocument,
    options
  );
}
export function useVisitsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    VisitsQuery,
    VisitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VisitsQuery, VisitsQueryVariables>(
    VisitsDocument,
    options
  );
}
export type VisitsQueryHookResult = ReturnType<typeof useVisitsQuery>;
export type VisitsLazyQueryHookResult = ReturnType<typeof useVisitsLazyQuery>;
export type VisitsSuspenseQueryHookResult = ReturnType<
  typeof useVisitsSuspenseQuery
>;
export type VisitsQueryResult = Apollo.QueryResult<
  VisitsQuery,
  VisitsQueryVariables
>;
export const VisitsWithFiltersDocument = gql`
  query VisitsWithFilters(
    $date: timestamptz!
    $providerQuery: userBoolExp!
    $medspaId: bigint!
    $status: [String!]!
  ) {
    visit(
      where: {
        startTime: { _gt: $date }
        provider: $providerQuery
        status: { _in: $status }
        medspa: { id: { _eq: $medspaId } }
      }
      orderBy: { startTime: ASC }
    ) {
      ...VisitFields
    }
  }
  ${VisitFieldsFragmentDoc}
`;

/**
 * __useVisitsWithFiltersQuery__
 *
 * To run a query within a React component, call `useVisitsWithFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitsWithFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitsWithFiltersQuery({
 *   variables: {
 *      date: // value for 'date'
 *      providerQuery: // value for 'providerQuery'
 *      medspaId: // value for 'medspaId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useVisitsWithFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    VisitsWithFiltersQuery,
    VisitsWithFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VisitsWithFiltersQuery,
    VisitsWithFiltersQueryVariables
  >(VisitsWithFiltersDocument, options);
}
export function useVisitsWithFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitsWithFiltersQuery,
    VisitsWithFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VisitsWithFiltersQuery,
    VisitsWithFiltersQueryVariables
  >(VisitsWithFiltersDocument, options);
}
export function useVisitsWithFiltersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    VisitsWithFiltersQuery,
    VisitsWithFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VisitsWithFiltersQuery,
    VisitsWithFiltersQueryVariables
  >(VisitsWithFiltersDocument, options);
}
export type VisitsWithFiltersQueryHookResult = ReturnType<
  typeof useVisitsWithFiltersQuery
>;
export type VisitsWithFiltersLazyQueryHookResult = ReturnType<
  typeof useVisitsWithFiltersLazyQuery
>;
export type VisitsWithFiltersSuspenseQueryHookResult = ReturnType<
  typeof useVisitsWithFiltersSuspenseQuery
>;
export type VisitsWithFiltersQueryResult = Apollo.QueryResult<
  VisitsWithFiltersQuery,
  VisitsWithFiltersQueryVariables
>;
export const NextVisitForClientDocument = gql`
  query NextVisitForClient($clientId: bigint!, $medspaId: bigint!) {
    visit(
      where: {
        clientId: { _eq: $clientId }
        medspaId: { _eq: $medspaId }
        startTime: { _gt: "now()" }
      }
      limit: 1
    ) {
      ...VisitFields
    }
  }
  ${VisitFieldsFragmentDoc}
`;

/**
 * __useNextVisitForClientQuery__
 *
 * To run a query within a React component, call `useNextVisitForClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextVisitForClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextVisitForClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useNextVisitForClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    NextVisitForClientQuery,
    NextVisitForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NextVisitForClientQuery,
    NextVisitForClientQueryVariables
  >(NextVisitForClientDocument, options);
}
export function useNextVisitForClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NextVisitForClientQuery,
    NextVisitForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NextVisitForClientQuery,
    NextVisitForClientQueryVariables
  >(NextVisitForClientDocument, options);
}
export function useNextVisitForClientSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NextVisitForClientQuery,
    NextVisitForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    NextVisitForClientQuery,
    NextVisitForClientQueryVariables
  >(NextVisitForClientDocument, options);
}
export type NextVisitForClientQueryHookResult = ReturnType<
  typeof useNextVisitForClientQuery
>;
export type NextVisitForClientLazyQueryHookResult = ReturnType<
  typeof useNextVisitForClientLazyQuery
>;
export type NextVisitForClientSuspenseQueryHookResult = ReturnType<
  typeof useNextVisitForClientSuspenseQuery
>;
export type NextVisitForClientQueryResult = Apollo.QueryResult<
  NextVisitForClientQuery,
  NextVisitForClientQueryVariables
>;
